import {mapGetters} from "vuex";

export default {
  name: "professional-club",
  data(){
    return{
      currentClient:null,
    }
  },
  computed:{
    ...mapGetters({
      user: 'profile/user'
    }),
  },
  methods:{
    findClient(id){
      for(let i in this.user.clients.data){
        if(this.user.clients.data[i] === id){
          this.currentClient = this.user.clients.data[i]
        }
      }
    }
  }
}